@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.animate-fade-in {
  animation: fade-in 2s ease-in-out;
}

body,
html {
  height: 100%;
  margin: 0;
}

.flex {
  display: flex;
}

.items-center {
  align-items: center;
}

.justify-center {
  justify-content: center;
}

.min-h-screen {
  min-height: 100vh;
}

.text-center {
  text-align: center;
}

.text-white {
  color: white;
}

.font-comic {
  font-family: "Comic Neue", cursive;
}

.rounded-md {
  border-radius: 0.375rem;
}

.p-8 {
  padding: 2rem;
}

.mt-4 {
  margin-top: 1rem;
}

.text-2xl {
  font-size: 1.5rem;
}

.bg-center {
  background-position: center;
}

.bg-no-repeat {
  background-repeat: no-repeat;
}

.subtitle {
  font-weight: bold;
  background: linear-gradient(45deg, #fc922b, #ffffff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  position: relative;
  display: inline-block;
  padding: 5px 10px;
}
